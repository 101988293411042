.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 56px; /* Add padding for navbar height */
}

.App-link {
  color: #61dafb;
}

.loader-container {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.bundle-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1600px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .bundle-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bundle-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

.bundle-card {
  background-color: #282c34;
  border-radius: 12px;
  padding: 30px;
  margin: 0 20px 30px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1600px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.bundle-card:hover {
  transform: translateY(-10px);
  border: 1px solid #00ac9d;
  box-shadow: 0 8px 16px rgba(0, 62, 36, 0.3),
              0 0 20px rgba(0, 172, 157, 0.3),
              0 0 40px rgba(0, 172, 157, 0.1);
  background-color: #1f2329;
}

.included-plans-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 1024px) {
  .bundle-card {
    grid-template-columns: 1fr 2fr;
    margin: 0 auto 30px;
  }
  
  .included-plans-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.partner-name {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  display: none;
}

@media (min-width: 768px) {
  .partner-name {
    display: block;
  }
}

@media (max-width: 768px) {
  .grid-container > div {
    min-height: 420px;
  }

  .App-header {
    padding: 0 !important;
  }

  .plan-details-container {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 20px !important;
  }
}

.plan-card {
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.plan-card:hover {
  transform: translateY(-10px);
  border: 1px solid #00ac9d;
  box-shadow: 0 8px 16px rgba(0, 62, 36, 0.3),
              0 0 20px rgba(0, 172, 157, 0.3),
              0 0 40px rgba(0, 172, 157, 0.1);
  background-color: #1f2329;
}

.buy-button {
  background-color: #00ac9d;
  color: #1a1d24;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
}

.buy-button:hover {
  background-color: #00c4b3;
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 172, 157, 0.6),
              0 0 40px rgba(0, 172, 157, 0.3),
              0 0 60px rgba(0, 172, 157, 0.1);
}

.buy-button:active {
  transform: translateY(0);
  box-shadow: 0 0 10px rgba(0, 172, 157, 0.4);
}

.activate-button {
  position: relative;
  overflow: hidden;
}

.activate-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 172, 157, 0.2);
}

.activate-button:active {
  transform: translateY(0);
}

.activate-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 60%);
  transform: scale(0);
  opacity: 0;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.activate-button:hover::after {
  transform: scale(1);
  opacity: 1;
}

/* Update CopyableCode component styles */
.copyable-code {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.copyable-code code {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  flex: 1;
}

.copyable-code button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.copyable-code button:hover {
  background-color: #f5f5f5;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.copyable-code button:active {
  transform: translateY(0);
  background-color: #e0e0e0;
}

.copyable-code button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, transparent 60%);
  transform: scale(0);
  opacity: 0;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.copyable-code button:hover::after {
  transform: scale(1);
  opacity: 1;
}

.coupon-container {
  background-image: url('https://web.getfleek.app/static/media/Subtract.f1fe4f20a6d75eb3a783.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.coupon-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 172, 157, 0.1) 0%,
    rgba(26, 29, 36, 0.9) 100%
  );
  z-index: 1;
}

.coupon-content {
  position: relative;
  z-index: 2;
}

.coupon-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.coupon-title {
  margin: 0;
  font-size: 20px;
  color: #000000;
  font-weight: 600;
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 2px 4px rgba(180, 131, 62, 0.2);
  }
  50% {
    box-shadow: 0 2px 12px rgba(180, 131, 62, 0.4);
  }
  100% {
    box-shadow: 0 2px 4px rgba(180, 131, 62, 0.2);
  }
}

@keyframes logoGlow {
  0% {
    filter: drop-shadow(0 0 0 rgba(180, 131, 62, 0));
    transform: translateY(0);
  }
  100% {
    filter: drop-shadow(0 4px 12px rgba(180, 131, 62, 0.3));
    transform: translateY(-2px);
  }
}

.plan-card img {
  transition: all 0.3s ease;
}

.plan-card:hover img {
  animation: logoGlow 0.3s ease forwards;
}
